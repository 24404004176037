//全体共通で使うCSS定義
html {
  &.open {
    overflow: hidden;

    body {
      overflow: hidden;
    }
  }
}

body {
  color: $textColor;
  font-family: $sanserif;
  line-height: $lineHeight;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pwid";

  @if $is-fixed==true {
    margin-top: $headerHeight;

    @include mq-max(md) {
      margin-top: $headerHeight-sp;
    }
  }
}


//  見出し
///////////////////////////////////////////////////////////////////////////////////////////////////////
h2,
h3,
h4,
h5 {
  line-height: 1.5;
}

h1 {
  font-size: 30px;

  @include mq-max(xs) {
    font-size: 24px;
  }
}

h2 {
  font-size: 28px;
  margin-bottom: 30px;

  @include mq-max(xs) {
    font-size: 22px;
    margin-bottom: 24px;
  }
}

h3 {
  font-size: 22px;
  margin-bottom: 15px;

  @include mq-max(xs) {
    font-size: 18px;
    margin-bottom: 12px;
  }
}

h4 {
  font-size: 21px;
  margin-bottom: 10px;

  @include mq-max(xs) {
    font-size: 18px;
  }
}

h5 {
  font-size: 18px;
  margin-bottom: 10px;

  @include mq-max(xs) {
    font-size: 16px;
  }
}

//  文章
///////////////////////////////////////////////////////////////////////////////////////////////////////
a {
  text-decoration: none;
  color: $keyColor;

  &[href^="javascript:void(0)"] {
    pointer-events: none;
  }

  &[target="_blank"] {
    &:after {
      @include fa('f35d');
      margin-left: 10px;
      -webkit-font-smoothing: antialiased;
    }
  }

  &[href$=".pdf"] {
    &:after {
      @include fa('f1c1');
      font-weight: 400;
      color: $red;
      margin-left: 10px;
      -webkit-font-smoothing: antialiased;
    }
  }

  &:not(.c-btn) {
    color: $keyColor;
    transition: color $transition;

    &:hover {
      color: darken($keyColor, 10%);
    }
  }
}

p {
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 2;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-max(xs) {
    font-size: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.is-large {
    font-size: 18px;
  }
}

blockquote {}


//  強調
strong {
  font-weight: 600;
}

//  画像
figure {
  img {
    width: 100%;
  }
}

// Lists
ul,
ol {
  list-style-type: none;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
//  電話番号CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////
[data-js="tel"] {
  &>a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

//お問い合わせのCSS
.p-contactarea-section {
  max-width: 1400px;
  margin: 0 auto;
}

.p-contactarea {
  padding-top: 70px;
  padding-bottom: 40px;
  background-color: $keyColor;

  @media screen and (max-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  &__inner {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    // padding-left: 60px;

    @media screen and (max-width:$contentWidth + $contentPadding) {
      padding: 60px $contentPadding !important;
    }

    @include mq-max(lg) {
      display: block;
      padding: 40px 15px 30px !important;
    }
  }

  &__message {
    text-align: center;
    color: $white;

    p {
      font-size: 24px;
    }

    font-weight: 600;

    @include mq-max(lg) {
      padding-right: 0;
      text-align: center;
      position: relative;
    }

  }

  &__title {
    text-align: center;
    color: $white;
    margin-bottom: 20px;

    p {
      font-size: 32px;
      line-height: 1.5;
    }

    span {
      font-size: 18px;
      display: block;
    }

    font-weight: 600;

    @include mq-max(lg) {
      padding-right: 0;
      text-align: center;
      margin-bottom: 30px;
      position: relative;
    }

  }

  &__contact {
    display: flex;

    @include mq-max(lg) {
      justify-content: center;
    }

    @include mq-max(sm) {
      display: block;
    }
  }

  &__tel {
    margin-right: 30px;

    @include mq-max(sm) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    .time {
      color: $white;
      display: flex;
      justify-content: center;

      @include mq-max(lg) {
        text-align: center;
      }

      dt {
        margin-right: 1em;
      }

      dd {
        &:not(:last-child) {
          margin-right: 1em;
        }
      }
    }

    .num {
      color: $white;
      display: block;
      font-size: 31px;
      font-weight: 600;
      position: relative;
      text-align: left;
      letter-spacing: 0.06em;
      padding-left: 40px;

      @include mq-max(lg) {
        text-align: center;
        padding-left: 40px;
      }

      &:before {
        @include fa('f095');
        position: absolute;
        top: 52%;
        left: 0;
        transform: translateY(-50%);
        transition: right $transition;
        font-size: 32px;

        @include mq-max(lg) {
          right: 250px;
        }
      }
    }
  }

  &__mail {
    text-align: center;

    .c-btn {
      font-size: 24px;
      padding: 21px 45px 21px 78px;
      background-color: $white;
      color: $keyColor;

      @include mq-max(lg) {
        font-size: 17px;
      }

      &:after {
        content: none;
      }

      &:before {
        @include fa('f0e0');
        position: absolute;
        top: 52%;
        left: 40px;
        transform: translateY(-50%);
        transition: right $transition;
        font-size: 29px;
      }

      &:hover {
        background-color: $keyColor;
        color: $white;
        border: 1px solid $white;
      }
    }
  }
}

//コンテンツの背景がグレー
.p-bg-grey {
  background-color: #EEEEEE;
}

.l-section-p-bg-grey {
  @include mq-max(content) {
    padding: 90px 30px 90px !important;
  }

  @include mq-max(md) {
    padding: 60px 30px 90px !important;
  }

  @include mq-max(sm) {
    padding: 60px 30px 90px !important;
  }

  @include mq-max(xs) {
    padding: 60px 15px 60px !important;
  }
}

//reasonに移行
.p-strength-imgbox {
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 50px;

  img {
    width: 100%;
  }
}

//選ばれる理由の数字アイコン


//アイコンの装飾
.ico_point {
  display: inline-block;
}

.ico_box {
  max-width: 50px;

  @media screen and (max-width: 1168px) {
    max-width: 45px;
  }

  @media screen and (max-width: 768px) {
    max-width: 45px;
  }

  img {
    width: 100%;
  }
}

//barの配置
.p-bar-position {
  display: block;
  line-height: 0px;
}

.p-bar-position-rela {
  position: relative;
}

//textを中央へ
.p-taC {
  text-align: center;
}

//fontを白に
.p-textC-white {
  color: #fff !important;
}

//文字間調整
.let-space {
  letter-spacing: 0.09em;
}

.p-pc-only {
  @include mq-max(xs) {
    display: none;
  }
}

//事業紹介に移行

.title-box {
  max-width: 760px;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;

  li {
    font-size: 20px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #BFBFBF;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
}

.solution_list_item {
  position: relative;
  // text-indent: 2em;
  padding: 0 0 0 40px;

  &:before {
    content: '';
    display: block;
    background: url('/inc/image/common/ico_check.png') no-repeat center / contain;
    width: 24px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    z-index: -1;

    @include mq-max(sm) {
      width: 24px;
      height: 50px;
    }

    @include mq-max(xs) {
      width: 24px;
      height: 50px;
    }
  }
}

.c-card01__thumb_product {
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover !important;
  width: 100%;
  padding-top: 67.26462% !important;
}

.p-title-ptb {
  padding-top: 20px;
  padding-bottom: 15px;
}

.p-flow-title {
  padding-bottom: 30px;
}

//shipping導線の背景BOX

.l-link-box {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    background-color: #242424;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .4;
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    width: calc(100% - (10px * 2));
    height: calc(100% - (15px * 2));
    border: 1px solid $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &__bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s cubic-bezier(0, -0.075, 0.27, 0.94);
    z-index: -1;
  }

  &>a {
    display: block;

    &:hover {
      .l-link-box__bg {
        transform: scale(1.1);
      }

      .l-link-box__more {
        &>span {
          &:after {
            right: -1em;
          }
        }
      }
    }
  }

  &__inner {
    max-width: $contentWidth;
    margin: 0 auto;
    padding: 50px 15px 50px;
    position: relative;
    z-index: 2;

    @include mq-max(sm) {
      padding: 50px 30px 50px;
    }
  }

  &__desc {
    text-align: center;
    color: $white;
    margin-bottom: 35px;
    font-size: 15px;

    @include mq-max(xs) {
      text-align: left;
    }
  }

  &__more {
    text-align: left;
    color: $white;
    margin: 0;
    font-size: 12px;
    line-height: 1;

    &>span {
      position: relative;
      display: inline-block;

      &:after {
        @include fa('f105');
        line-height: 1;
        position: absolute;
        top: 60%;
        right: -0.8em;
        transform: translateY(-50%);
        transition: all $transition;
      }
    }
  }

  //パーツ調整
  .c-ttl-2 {
    color: $white;

    &>small {
      color: $white;
    }
  }
}



//よくあるご質問へ移動

.p-faq-l-section {
  padding-top: 30px !important;
}

.p-anchor-l-section {
  padding: 0 0 30px !important;

  @media screen and (max-width: 1168px) {
    padding: 0 0 30px !important;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 30px !important;
  }
}

.c-accordion {
  padding-top: 30px;
}

//SDGsへ移行
.sdgs-logo-box {
  padding-top: 20px;
  padding-bottom: 20px;

  img {
    padding: 2.5px;
    width: 17%;
  }
}




//カテゴリ切り替え
.c-newslist {
  display: none !important;
}

.c-newslist--active {
  display: block !important;

  .c-card02__thumb {
    padding-top: 0;
  }
}

.p-news-button-position {
  padding-right: 0px !important;
}

.p-news-button {
  background-color: #ccc;
  padding: 20px 70px;
  border-radius: 5px;
  min-width: 110px;
  @media screen and (max-width: 768px) {
    padding: 20px 50px;
  }
  @media screen and (max-width: 668px) {
    padding: 12px 33px;
  }
  @media screen and (max-width: 468px) {
    padding: 12px 24px;
    font-size: 14px;
  }
}

.p-news-button--current {
  color: #fff;
  font-weight: bold;
  border-bottom: 2px solid $keyColor;
  background-color: $keyColor;
}

.p-c-anchor-news {
  justify-content: space-evenly !important;
  background-color: #fff !important;
  margin-bottom: 20px !important;
  @media screen and (max-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 30px !important;
  }
}

.p-l-section-news {
  padding-top: 30px !important;
}

//スライダーの装飾
.p-slider-box {
  padding-left: 10px;
  padding-right: 10px;
}

//商品紹介のWP
.c-introlist {
  display: none !important;
}

.c-introlist--active {
  display: flex !important;
  .c-card02__thumb {
    padding-top: 0;
  }
}

.p-intro-button--current {
  color: $keyColor;
  font-weight: bold;
  // font-size: 50px;
  border-bottom: 2px solid $keyColor;
}