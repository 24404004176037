.l-f {
  background-color:$white;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 58px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq-max(content) {
      padding: 62px 22px 52px;
    }
    @include mq-max(bp700) {
      flex-direction: column;
      padding: 60px 22px 49px;
    }
  }
  &__logo {
    max-width: 290px;
    width: 100%;
    @include mq-max(bp700) {
      margin-bottom: 42px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  &__links {
    display: flex;
    @include mq-max(bp700) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__item {
    margin-right: 44px;
    @include mq-max(sm) {
      margin-right: 30px;
    }
    @include mq-max(bp700) {
      margin-right: 0;
      margin-bottom: 16px;
    }
    &:last-child {
      margin-right: 0;
      @include mq-max(bp700) {
        margin-bottom: 0;
      }
    }
    &>a {
      font-size: 15px;
      font-weight: 600;
      color: $black;
      transition: all $transition;
      &:hover {
        color: $keyColor;
      }
    }
  }


  //Copyright
  &__copyright {
    font-size: 15px;
    text-align: center;
    display: block;
    color: #000;
    background-color: $white;
    padding: 30px 5px;
    border-top: 1px solid $gray;
    @include mq-max(sm) {
      font-size: 10px;
      padding: 18px 5px;
    }
  }


  //下層フッター
  &--lineTop {
    border-top: 1px solid $gray;
  }
}
